import AddressDTO from "@/dto/AddressDTO";

export default class UpdateAddressRequest {
    public customerId!: number;

    public address!: AddressDTO;

    constructor(id: number, address: AddressDTO) {
        this.customerId = id;
        this.address = address;
    }
}