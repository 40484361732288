
























import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {WorkspaceType} from "@/dto/auth/Workspace";
import CompanyService from "@/services/CompanyService";
import UpdateAddressRequest from "@/dto/UpdateAddressRequest";
import PersonService from "@/services/PersonService";
import AddressDTO from "@/dto/AddressDTO";
import {STATE_LIST} from "@/constants/ProfileConstants";
import UserDTO from "@/dto/UserDTO";
import Workspaces from "@/state/Workspaces";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import {validate} from "@/utils/ComponentUtils";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	computed: {
		states(): Array<string> {
			return STATE_LIST;
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {PortalAddress, PortalInput, AutoComplete}
})
export default class Address extends Vue {

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    @Auth.State("user")
    private currentUser!: UserDTO;

    private address = new AddressDTO();

    private tabIndex = 0;

    private message = "";

    private successful = false;

    public created(): void{
        switch (Workspaces.getCurrentType){
          case WorkspaceType.PERSON: this.loadPersonAddress(); break;
          case WorkspaceType.COMPANY: this.loadCompanyAddress(); break;
        }
    }


    private loadCompanyAddress(): void {
        this.startLoading();
        this.message = "";
        CompanyService.getAddressById(Workspaces.getCurrent.id).then(
            (response) => {
                if (response.data) {
                    this.address = response.data;
                }
				if (!this.address.state) {
					this.address.state = "NY"
				}
                this.tabIndex = this.address.foreignAddress ? 1 : 0;
                this.successful = true;
                this.stopLoading();
            },
            (error) => {this.errorHandle(error)}
        ).then(() => {
            this.stopLoading();
        })
    }

  private loadPersonAddress(): void {
    this.startLoading();
    this.message = "";
    PersonService.getAddressById(Workspaces.getCurrent.id).then(
        (response) => {
          if (response.data) {
            this.address = response.data;
            if (!this.address.state && this.address.isResidential) {
              this.address.state = "NY"
            }
          }
          this.tabIndex = this.address.foreignAddress ? 1 : 0;
          this.successful = true
        },
        (error) => {
          this.errorHandle(error)
        }
    ).then(
        () => this.stopLoading()
    )
  }

    get isForeign() : boolean {
        return !!this.address.foreignAddress;
    }

    private saveAddress(): void {
        this.message = "";
        validate(this).then(
            (isValid) => {
                if (isValid) {
                    switch (Workspaces.getCurrent.type) {
                        case WorkspaceType.PERSON:
                            this.savePersonAddress();
                            break;
                        case WorkspaceType.COMPANY:
                            this.saveCompanyAddress();
                            break;
                    }
                }
            }
        )
    }

	private savePersonAddress(): void {
        this.startLoading();
        let req = new UpdateAddressRequest(Workspaces.getCurrent.id, this.address);
        PersonService.saveAddress(req).then(
            () => {this.successHandle()},
            (error) => {this.errorHandle(error)}
        ).then(() => this.stopLoading());
    }

    private successHandle(): void {
        this.successful = true;
        this.message = String(this.$t("util.success.address"));
    }

    private errorHandle(error: any): void {
        this.successful = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
    }

    private saveCompanyAddress(): void {
        this.startLoading();
        let req = new UpdateAddressRequest(Workspaces.getCurrent.id, this.address);
        CompanyService.saveAddress(req).then(
            () => {this.successHandle()},
            (error) => {this.errorHandle(error)}
        ).then(() => this.stopLoading())
    }
}
